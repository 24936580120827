import * as _ from 'lodash-es';
import React, { useState } from 'react';
import {
  format as dateFnsFormat,
  formatDistance,
  formatRelative,
  parseISO,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useInterval } from '@chakra-ui/react';

import { TimeToMillisInput, timeToMillis } from 'lib/utils';
import { useTimezone } from 'components/SessionContext';

// This matches with the fallback in `infer_user_timezone`
export const DEFAULT_FALLBACK_SERVER_TIMEZONE = 'America/New_York';

const browserTimezone =
  typeof window !== 'undefined'
    ? _.memoize(() => {
        try {
          return Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch {}
        return null;
      })
    : () => null;

const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

const CURRENT_YEAR = new Date().getFullYear();

const formatWithoutCurrentYear = (
  date: Date,
  format: string,
  enabled = true,
) => {
  if (enabled && date.getFullYear() === CURRENT_YEAR) {
    // TODO: this isn't handling all cases correctly, is there a better way to do this?
    return (
      format
        .replace(/(, yyyy|yyyy[/-])/, '')
        // these will be innacurate for non-USA
        .replace(/^PP$/, 'MMM d') // e.g. Jan 2
        .replace(/^PPpp$/, 'MMM d, pp') // e.g. Jan 2, 3:04 PM
        .replace(/^P$/, 'MM/dd')
    ); // e.g. 01/02
  }

  return format;
};

export const formatTime = (
  time: TimeToMillisInput,
  format: string,
  timeZone?: string | null,
  {
    /** Exclude the _current_ year from output string  */
    excludeCurrentYear = false,
  } = {},
) => {
  // if time is a day string (i.e. yyyy-mm-dd), just reformat it without timezone offsets
  if (typeof time === 'string' && ISO_DATE_REGEX.test(time)) {
    const date = parseISO(time);
    return dateFnsFormat(
      date,
      formatWithoutCurrentYear(date, format, excludeCurrentYear),
    );
  }

  const millis = time ? timeToMillis(time) : null;
  if (!millis) return null;

  const date = new Date(millis);

  format = formatWithoutCurrentYear(date, format, excludeCurrentYear);

  timeZone ||= browserTimezone();

  return timeZone
    ? formatInTimeZone(millis, timeZone, format)
    : dateFnsFormat(millis, format);
};

/**
 * Formats a timestamp `time` into a localized string in the given `format`.
 * See https://date-fns.org/v2.28.0/docs/format for valid formats
 */
export const FormatTime: React.FC<{
  time?: TimeToMillisInput;
  format?: string;
  excludeCurrentYear?: boolean;
  timezone?: string;
}> = ({ time, format = 'PPpp', excludeCurrentYear, timezone }) => {
  const userTimezone = useTimezone();

  const timeZone = timezone || userTimezone;

  const formatted = formatTime(time || Date.now(), format, timeZone, {
    excludeCurrentYear,
  });

  return formatted ? <>{formatted}</> : null;
};

export const relativeTime = (
  from: TimeToMillisInput,
  to?: TimeToMillisInput,
  {
    timeZone,
    excludeCurrentYear = false,
    format = 'PPpp',
  }: {
    timeZone?: string | null;
    excludeCurrentYear?: boolean;
    format?: string;
  } = {},
) => {
  const fromM = from ? timeToMillis(from) : null;
  const toM = to ? timeToMillis(to) : Date.now();
  if (!fromM || !toM) return null;

  const delta = toM - fromM;
  return delta < 15 * 60000 // 15 minutes
    ? formatDistance(fromM, toM, { addSuffix: true, includeSeconds: true }) // e.g. 5 minutes ago
    : delta < 24 * 60 * 60000 // 24 hours
    ? // TODO: does not work in different timezone than browser:
      formatRelative(fromM, toM) // e.g. today at 04:30 AM
    : formatTime(fromM, format, timeZone, { excludeCurrentYear });
};

export const RelativeTime: React.FC<{
  time: TimeToMillisInput;
  to?: TimeToMillisInput;
  updateInterval?: number;
  format?: string;
  excludeCurrentYear?: boolean;
}> = ({ time, to, updateInterval = 10000, excludeCurrentYear, format }) => {
  const timeZone = useTimezone();

  const rel = relativeTime(time, to, { timeZone, excludeCurrentYear, format });

  const [, update] = useState(false);
  useInterval(() => update((v) => !v), updateInterval);

  return rel ? <>{rel}</> : null;
};

export const formatCurrency = (cents: number, short = true) => {
  const dollarFraction = cents % 100;

  return (cents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: short && !dollarFraction ? 0 : 2,
    maximumFractionDigits: short && !dollarFraction ? 0 : 2,
  });
};

// only supports USD currency
export const FormatCurrency: React.FC<{
  value: number;
  currency?: string;
  short?: boolean;
}> = ({ value, currency: _TODO = 'USD', short = true }) => {
  value = _.round(value / 100, 2);
  const sign = value < 0 ? '-' : null;
  value = Math.abs(value);
  return (
    <>
      {sign}${short && Number.isInteger(value) ? value : value.toFixed(2)}
    </>
  );
};

/**
 * Only re-formats ISO phone number strings within the USA.
 * @param phone should be in format: "+12345678901"
 */
export const FormatPhone: React.FC<{ phone: string }> = ({ phone }) => {
  if (!phone || !/^\+1\d{10}$/.test(phone)) return <>{phone}</>;
  return (
    <>
      ({phone.slice(2, 5)}) {phone.slice(5, 8)}-{phone.slice(8)}
    </>
  );
};
